import React, { useCallback } from 'react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { AxiosError } from 'axios'
import { GoogleLoginResponse, useGoogleLogin } from 'react-google-login'
import { ParsedUrlQuery } from 'querystring'

import { Button } from 'components/ui'

import { save } from 'lib/storage'
import { createAdminUser, requestVerification } from 'lib/gravy/adminUser'
import { LOCAL_STORAGE_API_URL, LOCAL_STORAGE_GOOGLE_RESPONSE } from 'lib/gravy'
import { buildValidationMessage } from 'lib/validation'

import useToast from 'hooks/useToast'

const Login = (): JSX.Element => {
  const toast = useToast()
  const router = useRouter()
  const { redirect } = router.query

  const navigateToSetup2FA = useCallback(
    (adminUser) => {
      const query: ParsedUrlQuery = { id: adminUser.id }
      if (redirect) {
        query.redirect = redirect
      }

      router.push({
        pathname: '/secure',
        query
      })
    },
    [redirect]
  )

  const navigateToVerify2FA = useCallback(
    (adminUser) => {
      const query: ParsedUrlQuery = { id: adminUser.id }
      if (redirect) {
        query.redirect = redirect
      }

      requestVerification({ id: adminUser.id })
        .then(() => {
          router.push({
            pathname: '/verify',
            query
          })
        })
        .catch(() => {
          toast.error('Failed to send verification code')
          throw new Error('Failed to send verification code')
        })
    },
    [redirect]
  )

  const handleFailedUserCreation = useCallback((error: AxiosError) => {
    let messages: string

    if (error.response.status === 422) {
      messages = buildValidationMessage(error.response.data.messages)
    }

    toast.error('Failed to authenticate via Google OAuth', {
      body: messages || null
    })
    throw new Error('Failed to authenticate via Google OAuth')
  }, [])

  const onSuccess = useCallback(async (response: GoogleLoginResponse) => {
    const {
      tokenId: token,
      tokenObj: { expires_at: expirationTime },
      profileObj: { name, imageUrl: avatarUrl, googleId: googleUid, email }
    } = response

    const user = {
      email,
      expirationTime,
      googleUid,
      avatarUrl,
      name,
      token
    }

    save(LOCAL_STORAGE_GOOGLE_RESPONSE, user)
    save(LOCAL_STORAGE_API_URL, process.env.gravyApiUrl)

    const adminUser = await createAdminUser(user)
      .then((response) => response.data)
      .catch(handleFailedUserCreation)

    if (adminUser.created) {
      navigateToSetup2FA(adminUser)
    } else {
      navigateToVerify2FA(adminUser)
    }
  }, [])

  const onFailure = useCallback(() => {
    toast.error('Failed to authenticate via Google OAuth')
  }, [])

  const { signIn: onGoogleSignIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId: process.env.googleClientId,
    accessType: 'online'
  })

  return (
    <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="text-center">
          <Image
            className="mx-auto h-12 w-auto"
            src="/logo.svg"
            alt="Gravy"
            width="60"
            height="60"
          />
        </div>

        <h1 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Gravy (Admin)
        </h1>

        <div className="text-center mt-4">
          <Button theme="secondary" size="xlarge" onClick={onGoogleSignIn}>
            Sign in with Google
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Login
