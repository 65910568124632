import camelCase from 'lodash/camelCase'
import startCase from 'lodash/startCase'

type Errors = {
  [key: string]: string[]
}

export const buildValidationMessage = (errors: Errors): string => {
  const errorMessages = []
  const errorKeys = Object.keys(errors)

  errorKeys.forEach((key) => {
    errors[key].forEach((message: string) => {
      const title = startCase(camelCase(key))
      errorMessages.push(`${title} ${message}`)
    })
  })

  return errorMessages.join(', ')
}
