import { decamelizeKeys } from 'humps'

import { API } from './'

export const createAdminUser = async (attributes) => {
  const params = decamelizeKeys(attributes)

  return API.post('admin_users', { ...params })
}

export const requestVerification = async ({ id }) => {
  return API.post(`admin_users/${id}/verifications`)
}
